<template>
  <div class="h-100 row m-0 mt-md-5 mb-md-5">
    <div class="col-md-3">
      <div class="card-header pt-5 pb-5 h3 animate__animated animate__slideInDown">联系我们</div>
      <div class="list-group p-0">
        <a href="/train" class="list-group-item list-group-item-action list-group-item-primary text-left animate__animated animate__slideInDown">人才培养</a>
        <a href="/certificate" class="list-group-item list-group-item-action list-group-item-primary text-left animate__animated animate__slideInDown active">证书查询</a>
      </div>
<!--      <div class="card-footer text-left animate__animated animate__slideInUp">-->
<!--        <div class="pt-4 pb-4">-->
<!--          <h6><i class="fa fa-user" aria-hidden="true"></i>  联系人：Liych</h6>-->
<!--          <h6><i class="fa fa-phone" aria-hidden="true"></i> 联系电话：13293240899</h6>-->
<!--          <h6><i class="fa fa-map-marker" aria-hidden="true"></i>  联系地址：北京市朝阳区建国门</h6>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <div class="col-md-9 text-center animate__animated animate__slideInUp">
      <form class="text-left w-50 form-cen mb-5">
        <div class="form-group">
          <label for="exampleInputEmail1">姓名</label>
          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1">身份证号</label>
          <input type="text" class="form-control" id="exampleInputPassword1">
        </div>
        <div class="text-danger pt-2 pb-2">注意：请输入详细信息查询。</div>
        <button type="submit" class="btn btn-primary pl-5 pr-5 w-100">查  询</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "certificate"
}
</script>

<style scoped>
.form-cen {
  margin: 0 auto;
}
</style>